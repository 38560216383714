import type {OtelService} from '@shopify/opentelemetry-sdk';

import {options} from './options';

export const getOtelService: () => Promise<OtelService> = () => {
  return new Promise((resolve, reject) => {
    import('@shopify/opentelemetry-sdk')
      .then((OpentelImport) => {
        const DefaultOtelService = OpentelImport.DefaultOtelService;
        const otelService = new DefaultOtelService(options);
        resolve(otelService);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
