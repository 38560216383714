import type {DefaultOtelServiceOptions} from '@shopify/opentelemetry-sdk';

import {metrics} from './metrics';

export const options: DefaultOtelServiceOptions = {
  serviceName: 'shop-js',
  throttleLimit: 1000,
  prefixMetric: false,
  metrics,
  // NOTE:
  // If OtelService env variable is any value different from 'staging' or 'production',
  // it will attempt to send otel metrics to http://localhost:4318/v1/metrics
  // Since we are not running the otel collector locally, we will always use the staging.
  // eslint-disable-next-line no-process-env
  env: process.env.NODE_ENV === 'production' ? 'production' : 'staging',
};
