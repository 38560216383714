import {BugsnagLight} from '@shopify/bugsnag-light-core';
import type {BreadcrumbType, NotifyOptions} from '@shopify/bugsnag-light-core';

import {
  createBugsnagParams,
  getReleaseStage,
  addGenericBugsnagOnError,
} from './utils';
import {RELEASE_STAGES} from './constants';

let client: BugsnagLight | undefined;

const Bugsnag = {
  /**
   * Start Bugsnag. Must be called before any other Bugsnag methods.
   * @param params
   * @param params.metadata Initial metadata to send with all captured events.
   */
  start: ({metadata}: {metadata: any}) => {
    if (client) {
      // eslint-disable-next-line no-console
      console.log('Bugsnag.start() has already been called. Ignoring.');
      return;
    }
    client = new BugsnagLight(createBugsnagParams(metadata));
    addGenericBugsnagOnError(client);
  },

  /**
   * Add a breadcrumb to the stack. Breadcrumbs are sent to Bugsnag when an error is sent, providing additional
   * data on what happened prior to the error.
   * @param {string} name The name of the breadcrumb.
   * @param {any} metaData Any additional data to send with the breadcrumb.
   * @param {BreadcrumbType} type The type of breadcrumb (i.e. "navigation" or "request"). See BreadcrumbType for options.
   */
  leaveBreadcrumb: (name: string, metaData: any, type: BreadcrumbType) => {
    if (!client) {
      // eslint-disable-next-line no-console
      console.log('Bugsnag.leaveBreadcrumb() called before start().');
      return;
    }

    if (getReleaseStage() === RELEASE_STAGES.spin) {
      // eslint-disable-next-line no-console
      console.log('[Bugsnag leaveBreadcrumb called]', name, metaData, type);
      return;
    }

    client.leaveBreadcrumb(name, metaData, type);
  },

  /**
   * Send a notification to Bugsnag.
   * @param {Error} error The error to log.
   * @param {BeforeNotifyCallback} onBeforeNotify Optional callback allows modifying the event before it is sent to Bugsnag.
   * Use to set `severity` or add metadata with the `addMetadata` function call. If callback returns false, the event will
   * not be sent. Should behave similar to the `onError` callback of the official Bugsnag client:
   * https://docs.bugsnag.com/platforms/javascript/reporting-handled-errors/#customizing-diagnostic-data
   * @param options
   */
  notify: (error: Error, options?: NotifyOptions) => {
    if (!client) {
      // eslint-disable-next-line no-console
      console.log('Bugsnag.notify() called before start().');
      return;
    }

    if (getReleaseStage() === RELEASE_STAGES.spin) {
      // eslint-disable-next-line no-console
      console.log('[Bugsnag notify called]', error);
      return;
    }

    client.notify(error, options);
  },
};

export default Bugsnag;
