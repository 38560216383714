/**
 *
 * @param {boolean} session Whether to use sessionStorage or localStorage.
 * @returns {boolean} Whether the storage is available.
 */
function storageAvailable(session?: boolean) {
  const type = session ? 'sessionStorage' : 'localStorage';

  try {
    const storage = window[type];
    const testValue = '__storage_test__';
    storage.setItem(testValue, testValue);
    storage.removeItem(testValue);
    return true;
  } catch (_e) {
    return false;
  }
}

/**
 * Sets the value of the item in the given storage.
 * @param {string} name The name of the item.
 * @param {string} value The value of the item.
 * @param {object} options The options for the storage.
 * @param {boolean} options.session Whether to use sessionStorage or localStorage.
 * @returns {boolean} Whether the operation was successful.
 */
export function setStorageItem(
  name: string,
  value: string,
  {session}: {session?: boolean} = {},
): boolean {
  if (!storageAvailable(session)) {
    return false;
  }

  const storage = session ? 'sessionStorage' : 'localStorage';
  window[storage].setItem(name, value);
  return true;
}

/**
 * Gets the value of the item in the given storage.
 * @param {string} name The name of the item.
 * @param {object} options The options for the storage.
 * @param {boolean} options.session Whether to use sessionStorage or localStorage.
 * @returns {string | null} The value of the item or null if it doesn't exist
 */
export function getStorageItem(
  name: string,
  {session}: {session?: boolean} = {},
): string | null {
  if (!storageAvailable(session)) {
    return null;
  }

  const storage = session ? 'sessionStorage' : 'localStorage';
  return window[storage].getItem(name);
}

/**
 * Deletes the item in the given storage.
 * @param {string} name The name of the item.
 * @param {object} options The options for the storage.
 * @param {boolean} options.session Whether to use sessionStorage or localStorage.
 * @returns {boolean} Whether the operation was successful.
 */
export function removeStorageItem(
  name: string,
  {session}: {session?: boolean} = {},
): boolean {
  if (!storageAvailable(session)) {
    return false;
  }

  const storage = session ? 'sessionStorage' : 'localStorage';
  window[storage].removeItem(name);
  return true;
}
