import {TrekkieAttributes, TrekkieDefaultAttribute} from './types';

/**
 * Get the specified attributes from Trekkie default attributes.
 * Note: window.ShopifyAnalytics is only available on a Shopify Storefront
 * @param {TrekkieDefaultAttribute[]} attributes The keys of the attributes to retrieve
 * from the Trekkie default attributes.
 * @returns {TrekkieAttributes} The values of the requested Trekkie attributes.
 */
export async function getTrekkieAttributes(
  ...attributes: TrekkieDefaultAttribute[]
): Promise<TrekkieAttributes> {
  // If ShopifyAnalytics or analytics are not available on the window object,
  // then the element is being rendered somewhere outside the scope of the storefront/arrive-website.
  if (!window.ShopifyAnalytics && !window.analytics) {
    return {} as TrekkieAttributes;
  }

  let defaultAttributesPromise: Promise<TrekkieAttributes>;

  const trekkiePromiseIsAvailable = Boolean(window.trekkie?.ready);
  if (trekkiePromiseIsAvailable) {
    defaultAttributesPromise = getTrekkiePromise();
  } else {
    window.trekkie = window.trekkie || [];
    defaultAttributesPromise = new Promise((resolve) => {
      window.trekkie.push([
        'ready',
        () => {
          resolve(getTrekkiePromise());
        },
      ]);
    });
  }
  const defaultAttributes = await defaultAttributesPromise;
  return attributes.reduce((selectedAttributes, attributeName) => {
    const attributeValue = defaultAttributes[attributeName];
    if (attributeValue !== undefined) {
      selectedAttributes[attributeName] = attributeValue;
    }
    return selectedAttributes;
  }, {} as TrekkieAttributes);
}

/**
 *  Wait a max of 10 seconds before giving up on waiting for Trekkie to be ready.
 *  @returns {Promise<TrekkieAttributes>} A promise that resolves with the Trekkie default attributes.
 */
function getTrekkiePromise(): Promise<TrekkieAttributes> {
  let timer: NodeJS.Timeout;

  const promise = Promise.race<TrekkieAttributes>([
    new Promise(
      (resolve) =>
        (timer = setTimeout(() => resolve({} as TrekkieAttributes), 10000)),
    ),
    new Promise((resolve) => {
      const readyPromise =
        window.ShopifyAnalytics?.lib?.ready || window.analytics?.ready;

      readyPromise?.(() => {
        const trekkie =
          window.ShopifyAnalytics?.lib?.trekkie || window.analytics?.trekkie;
        resolve(trekkie.defaultAttributes);
      });
    }),
  ]);
  return promise.finally(() => clearTimeout(timer));
}
