import Bugsnag from '../bugsnag';

/**
 * Creates an element locator that will call the provided callbacks when elements are added to the DOM.
 * @param {object} params The root parameter object.
 * @param {string} params.selector Any valid CSS selector
 * @param {Function} params.onElementFound Called when an element is added to the DOM
 * @returns {MutationObserver} An object that handles starting and stopping a mutation observer
 */
export function createElementLocator<TElementType extends Element = Element>({
  selector,
  onElementFound,
}: {
  selector: string;
  onElementFound: (element: TElementType) => void;
}): MutationObserver {
  const instances = new WeakSet<TElementType>();

  const observer = new MutationObserver((mutations) => {
    let hasNewNodes = false;

    for (const mutation of mutations) {
      if (mutation.addedNodes.length > 0) {
        hasNewNodes = true;
        break;
      }
    }

    if (hasNewNodes) {
      locateElements();
    }
  });

  /**
   * Locates elements that match the provided selector, and calls the onElementFound callback for each one.
   */
  function locateElements() {
    document.querySelectorAll<TElementType>(selector).forEach((element) => {
      if (!instances.has(element)) {
        onElementFound(element);
        instances.add(element);
      }
    });
  }

  /**
   * Waits for the DOM to be ready before starting the mutation observer.
   */
  async function locateAndObserveElements() {
    await waitForDOM();
    locateElements();

    observer.observe(document.body || document.documentElement, {
      childList: true,
      subtree: true,
    });
  }

  locateAndObserveElements();

  return observer;
}

/**
 * Creates an element visibility observer that will call the provided callbacks when elements become visible.
 *
 * If an element is visible, and opacity of itself, or it's parent elements is '1', we will call the onVisible callback.
 * If an element is visible, and opacity of itself, or it's parent elements is not '1', we will call the onFallback callback.
 * @param {object} params The root parameter object.
 * @param {Function} params.onVisible Called when an element is visible
 * @param {Function} params.onFallback Called when an element is visible but has invalid styles
 * @returns {IntersectionObserver} An object that handles registering elements, and disabling the observer
 */
export function createElementVisibilityObserver<
  TElementType extends Element = Element,
>({
  onVisible,
  onFallback,
}: {
  onVisible: (element: TElementType) => void;
  onFallback: (element: TElementType) => void;
}): IntersectionObserver {
  const observer = new IntersectionObserver(
    (entries) => {
      for (const entry of entries) {
        const {target, isIntersecting} = entry;

        if (isIntersecting) {
          if (validateStyles(target)) {
            onVisible(target as TElementType);
          } else {
            onFallback(target as TElementType);
          }

          observer.unobserve(target);
        }
      }
    },
    {
      threshold: 1,
    },
  );

  /**
   * Validates that styles of a given element are sufficient enough to be considered visible.
   * @param {Element} element The element to check
   * @returns {boolean} Whether the element is visible
   */
  function validateStyles(element: Element): boolean {
    let currentElement: Element | null = element;

    while (currentElement) {
      // Intersection observer does not respect opacity
      if (!['', '1'].includes(getComputedStyle(currentElement).opacity)) {
        return false;
      }

      currentElement = currentElement.parentElement;
    }

    return true;
  }

  return observer;
}

/**
 * Returns whether the given element is available or not. Emits a bugsnag error if not available.
 * @param {HTMLElement | null} element The html element to check for availability
 * @param {boolean} emitErrorIfNotAvailable Whether to emit a bugsnag error if the element is not available
 * @returns {boolean} Where the element is available or not
 */
export function isHTMLElementAvailable(
  element: HTMLElement | null,
  emitErrorIfNotAvailable = true,
): element is HTMLElement {
  const available = Boolean(element);
  if (!available && emitErrorIfNotAvailable) {
    emitBugsnagErrorOnElementNotAvailable();
  }
  return available;
}

/**
 * Emit monorail event on html element not available
 *
 */
function emitBugsnagErrorOnElementNotAvailable(): void {
  Bugsnag.notify(new Error(`HTML Element was not provided`));
}

/**
 * Utility method to wait for the DOM to be ready, if the body tag already exists, we will
 * return without waiting for the DOMContentLoaded event.
 * @returns {Promise<void>} A promise that resolves when the DOM is ready
 */
function waitForDOM(): Promise<void> {
  if (document.body) {
    return Promise.resolve();
  }

  return new Promise((resolve) => {
    window.addEventListener('DOMContentLoaded', () => resolve());
  });
}

/**
 * Copies the template element from the template source to the DOM. Then extracts
 * the template contents and appends it as a child to the parent element.
 * @param {string} templateSource A string containing the <template> HTML element, with an id.
 * @param {string} templateElementId The id of the template element passed to templateSource.
 * @param {object} parentElement The parent element to append the template contents to.
 */
export function copyTemplateToDom(
  templateSource: string,
  templateElementId: string,
  parentElement: ParentNode,
) {
  const existingTemplate: HTMLTemplateElement | null =
    parentElement.querySelector(`#${templateElementId}`);
  const existingTemplateDiv =
    existingTemplate?.parentElement as HTMLDivElement | null;

  const templatesElem: HTMLDivElement =
    existingTemplateDiv ?? document.createElement('div');
  templatesElem.innerHTML = templateSource;
  templatesElem.style.display = 'none';

  // eslint-disable-next-line no-warning-comments
  // TODO: Fix TS error
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if ('innerHTML' in parentElement) parentElement.innerHTML = '';
  parentElement.prepend(templatesElem);

  const template: HTMLTemplateElement = parentElement.querySelector(
    `#${templateElementId}`,
  )!;
  const templateContent = template.content;
  parentElement.appendChild(templateContent.cloneNode(true));
}
