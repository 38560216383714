/**
 * @file Contains a function to restrict resizing iframe width beyond the boundaries of viewport.
 * We are facing an issue regarding a new IOS update (17.4) which is firing resize event on the iframe
 * with width higher than expected. (Possibly a regression in Safari IOS 17.4)
 *
 * Events are fired twice: 1 before the content wrapper has a proper width, and 1 after with a the proper width.
 */

/**
 * Calculate the size with boundaries to the parent element of iframe.
 * @param width - new width of the iframe
 * @param iframe - the iframe element
 * @returns {ResizeEvent} - the new size of the iframe
 */
export function constraintWidthInViewport(
  width: number,
  iframe: HTMLIFrameElement,
): number {
  /**
   * At first render, the contentWidth is 0, so we need to wait for the next frame to get the correct value.
   * Meanwhile, we accept what it comes from the browser forcing the element to push.
   */
  const contentWidth = iframe.parentElement?.offsetWidth ?? 0;
  return Math.min(contentWidth, width) || width;
}
