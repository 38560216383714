import type {OtelService} from '@shopify/opentelemetry-sdk';

import {getOtelService} from './opentelemetry';

let OtelServicePromise: Promise<OtelService>;
let retriesLeft = 3;

export const getOtelServiceSingleton: (
  overrideSingleton?: boolean,
) => Promise<OtelService> = (overrideSingleton = false) => {
  if (OtelServicePromise && !overrideSingleton) {
    return OtelServicePromise;
  }

  OtelServicePromise = getOtelService();
  return OtelServicePromise;
};

export const getOtelServiceSingletonWithRetries: () => Promise<OtelService> =
  async () => {
    try {
      const opentelService = await getOtelServiceSingleton(true);
      return opentelService;
    } catch (error) {
      retriesLeft--;
      if (retriesLeft > 0) {
        return getOtelServiceSingletonWithRetries();
      }
      throw error;
    }
  };
