import {DefaultComponentAnalyticsContext} from '../../constants/loginDefault';

const ANALYTICS_CONTEXTS_TO_COMPACT = [
  DefaultComponentAnalyticsContext.CheckoutModal,
  DefaultComponentAnalyticsContext.ClassicCustomerAccounts,
  DefaultComponentAnalyticsContext.Web,
  DefaultComponentAnalyticsContext.SelfServe,
];

/**
 * @param {string} analyticsContext the analytics context string
 * @returns {boolean} the given comtext is eligible for compact layout
 */
export function checkEligibleForCompactLayout(
  analyticsContext: DefaultComponentAnalyticsContext,
): boolean {
  const applyCompactToAnalyticsContext = analyticsContext
    ? ANALYTICS_CONTEXTS_TO_COMPACT.includes(analyticsContext)
    : false;

  return applyCompactToAnalyticsContext;
}
