/**
 * Parses the string and returns an int or undefined if NaN.
 * @param {string} [value] A string that may be a number.
 * @returns {number | undefined} The parsed int or undefined if NaN.
 */
export function parseIntOrUndefined(value?: string): number | undefined {
  if (!value) return;

  const parsed = parseInt(value, 10);
  if (isNaN(parsed)) return;

  return parsed;
}
