import {DefaultComponentAnalyticsContext} from '__deprecated__/constants/loginDefault';
import {parseErrorStack} from '@shopify/bugsnag-light-core';
import type {
  BugsnagClient,
  BugsnagEvent,
  ReleaseStage,
} from '@shopify/bugsnag-light-core';

import {checkEligibleForCompactLayout} from '../utils/isCompactLayout';
import config from '../../../config';
import {BUGSNAG_APP_ID, APP_VERSION} from '../constants';

import {RELEASE_STAGES} from './constants';

/**
 * Checks which environment shop-js is running in and returns the appropriate
 * release stage string. This corresponds to the Stage filter in the Bugsnag console.
 * @typedef ReleaseStage
 * @returns {ReleaseStage} The release stage that corresponds to this environment.
 */
export function getReleaseStage(): ReleaseStage {
  // Gets set at build time by rollup-replace
  // eslint-disable-next-line no-process-env
  const nodeEnv = process.env.NODE_ENV;

  switch (nodeEnv) {
    case 'spin':
      return RELEASE_STAGES.spin;
    case 'staging':
      return RELEASE_STAGES.staging;
    case 'production':
      return RELEASE_STAGES.prod;
    case 'development':
    default:
      return RELEASE_STAGES.dev;
  }
}

/**
 * Checks whether an error originated in shop-js.
 * @param {Error} error An error object.
 * @returns {boolean} `true` if the given error came from shop-js, `false` otherwise.
 */
export function isShopJsError(error: Error): boolean {
  const stack = parseErrorStack(error);
  if (stack.length === 0) return false;

  if (
    error.message?.includes('Backpressure applied') ||
    error.message?.includes(
      'A network failure may have prevented the request from completing',
    )
  )
    return false;

  return stack.some((frame) => frame.file?.includes('shopifycloud/shop-js'));
}

/**
 * Adds the URLs of all shop-js bundles on the page to the Bugsnag event metadata.
 * This does not tell us which bundle the error came from, but it's still valuable
 * data to have.
 * @param {BugsnagEvent} event The Bugsnag Event to add metadata to.
 */
function addBundleMetadata(event: BugsnagEvent) {
  const shopJsUrls = (
    Array.from(
      document.querySelectorAll('script[src*="/shop-js/"]'),
    ) as HTMLScriptElement[]
  ).map((scriptTag) => scriptTag.src);
  addMetadata(event, {shopJsUrls});
}

/**
 * Adds metadata to the Bugsnag event to indicate whether the window.Shopify.featureAssets
 * object exists. We want to be conscious of the payload we send to Bugsnag, so sending the
 * entire object is not ideal. This is a compromise.
 * @param {BugsnagEvent} event The Bugsnag Event to add metadata to.
 */
function addFeatureAssetsMetadata(event: BugsnagEvent) {
  const featureAssets: Record<string, any[]> | undefined =
    window.Shopify?.featureAssets?.['shop-js'];
  const featureAssetsNonEmpty = Boolean(
    featureAssets && Object.keys(featureAssets).length > 0,
  );
  addMetadata(event, {shopJsFeatureAssetsExist: featureAssetsNonEmpty});
}

/**
 * Adds metadata to the Bugsnag event to indicate whether the compact UX is being used.
 * @param {BugsnagEventType} event The Bugsnag Event to add metadata to.
 */
function addCompactUXMetadata(event: BugsnagEvent) {
  const compactShopLoginButton = Array.from(
    document.querySelectorAll('shop-login-button[compact]'),
  );
  compactShopLoginButton.filter((el) =>
    checkEligibleForCompactLayout(
      el.getAttribute('analytics-context') as DefaultComponentAnalyticsContext,
    ),
  );
  addMetadata(event, {compactUX: compactShopLoginButton.length > 0});
}

/**
 * Adds metadata to the Bugsnag event to identify the current domain.
 * @param {BugsnagEventType} event The Bugsnag Event to add metadata to.
 */
function addDomainMetadata(event: BugsnagEvent) {
  const domain = window?.location?.hostname;
  addMetadata(event, {domain});
}

/**
 * Adds the error class and error message as a grouping hash to the Bugsnag event.
 * @param {BugsnagEvent} event The Bugsnag Event to add groupHash to.
 */
function addGroupingHash(event: BugsnagEvent): void {
  const groupingHash = `${event.exceptions[0].errorClass}:${event.exceptions[0].message}`;
  event.groupingHash = groupingHash;
}

/**
 * Adds metadata to a Bugsnag event.
 * @param {BugsnagEvent} event The Bugsnag Event to add metadata to.
 * @param metadata
 */
function addMetadata(event: BugsnagEvent, metadata: any) {
  event.metaData = {...event.metaData, ...metadata};
}

/**
 * Adds the current URL to the Bugsnag event request.
 * @param {BugsnagEvent} event The Bugsnag Event to add metadata to.
 */
function addRequest(event: BugsnagEvent) {
  event.request = {
    url: window.location.href,
  };
}

/**
 * Adds the device information to the Bugsnag event.
 * @param {BugsnagEvent} event The Bugsnag Event to add metadata to.
 */
function addDevice(event: BugsnagEvent) {
  const now = new Date();
  const time = now.toISOString();
  event.device = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    locale: navigator.userLanguage || navigator.language,
    userAgent: navigator.userAgent,
    orientation: window.screen?.orientation?.type,
    time,
  };
}

/**
 * Adds the metadata and other relevant information to a Bugsnag event.
 * @param {BugsnagEvent} event The Bugsnag Event to add metadata to.
 */
function onError(event: BugsnagEvent) {
  addBundleMetadata(event);
  addFeatureAssetsMetadata(event);
  addCompactUXMetadata(event);
  addDomainMetadata(event);
  addGroupingHash(event);
  addRequest(event);
  addDevice(event);
}

/**
 * Creates a Bugsnag client params metadata and with the default params.
 * @param metadata
 * @returns {BugsnagLightParams} The Bugsnag Params.
 */
export function createBugsnagParams(metadata: any) {
  return {
    apiKey: config.bugsnagApiKey,
    appId: BUGSNAG_APP_ID,
    appVersion: APP_VERSION,
    metadata: {
      // eslint-disable-next-line no-process-env
      bundleLocale: process.env.BUILD_LOCALE,
      ...metadata,
    } as any,
    onError,
    releaseStage: getReleaseStage(),
  };
}

/**
 * Adds a generic Bugsnag error handler to the window object.
 * @param {BugsnagLight} bugsnagClient
 */
export function addGenericBugsnagOnError(bugsnagClient: BugsnagClient) {
  if (!window) return;

  window.addEventListener('error', (event) => {
    const {error} = event;
    if (error && isShopJsError(error)) {
      bugsnagClient.notify(error);
    }
  });
}
