/**
 * Utility function for the URL builders. Converts a boolean value that may be
 * undefined to a URL query parameter.
 * @param {string} key The name of the query parameter.
 * @param {boolean} value The boolean value. May be `undefined`.
 * @returns {object | null} If `value` is defined, returns an object with the key
 * being the query parameter and the value being the `value` parameter cast to string.
 * If `value` is undefined, returns `null`.
 */
export function booleanQueryParam(
  key: string,
  value?: boolean,
): null | Record<string, 'true' | 'false'> {
  return value === undefined
    ? null
    : {
        [key]: value ? 'true' : 'false',
      };
}
