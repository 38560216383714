import {getCookie} from './cookies';
import {getStorageItem, setStorageItem} from './storage';

export const STORAGE_KEY = 'signInWithShop';
export const STORAGE_KEY_TO_COOKIE_MAPPING = {
  following: 'shop_followed',
  modalDismissed: 'sign_in_with_shop_modal_dismissed',
};

export class StorageManager<TValueType = unknown> {
  private namespacedKey;
  private currentValue;

  constructor(
    private key: 'following' | 'modalDismissed',
    private defaultValue: TValueType,
  ) {
    this.namespacedKey = `${STORAGE_KEY}:${key}`;
    this.currentValue = this.initAndBackfillCurrentValue();
  }

  get value(): TValueType {
    return this.currentValue;
  }

  set(value: TValueType): void {
    this.currentValue = value;

    setStorageItem(this.namespacedKey, JSON.stringify(this.currentValue));
  }

  private initAndBackfillCurrentValue(): TValueType {
    const rawStorageValue = getStorageItem(this.namespacedKey);

    // If value does not exist in local storage, try the legacy cookies
    if (!rawStorageValue) {
      const rawCookie = getCookie(STORAGE_KEY_TO_COOKIE_MAPPING[this.key]);

      if (typeof rawCookie === 'string') {
        const cookieValue = (rawCookie === 'true') as TValueType;

        this.set(cookieValue);

        return cookieValue;
      }

      // If legacy cookie did not exist, return default value
      return this.defaultValue;
    }

    try {
      return JSON.parse(rawStorageValue);
    } catch (error) {
      return this.defaultValue;
    }
  }
}
