export interface MessageEventSource {
  isSourceOf: (event: MessageEvent) => boolean;
}

export class IFrameEventSource implements MessageEventSource {
  private readonly _source: HTMLIFrameElement;

  constructor(source: HTMLIFrameElement) {
    this._source = source;
  }

  isSourceOf(event: MessageEvent) {
    return event.source === this._source.contentWindow;
  }
}

export class WindowEventSource implements MessageEventSource {
  private readonly _source: Window;

  constructor(source: Window) {
    this._source = source;
  }

  isSourceOf(event: MessageEvent) {
    return event.source === this._source;
  }
}
