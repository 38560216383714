const ELEMENT_TAG = 'shop-heart-icon';
const ELEMENT_HEIGHT = '15';

export class ShopHeartIcon extends HTMLElement {
  #rootElement: ShadowRoot;
  #svgElement: SVGSVGElement | null = null;

  constructor() {
    super();

    const template = document.createElement('template');
    template.innerHTML = getTemplateContents();

    this.#rootElement = this.attachShadow({mode: 'open'});
    this.#rootElement.appendChild(template.content.cloneNode(true));

    this.#svgElement = this.#rootElement.querySelector('svg');
    this.#svgElement?.setAttribute('class', `${ELEMENT_TAG}--regular`);
  }

  setFilled(filled = true): void {
    this.#svgElement?.setAttribute(
      'class',
      `${ELEMENT_TAG}--${filled ? 'filled' : 'regular'}`,
    );
  }
}

/**
 * @returns {string} HTML content for the icon
 */
function getTemplateContents(): string {
  return `
    <style>
      :host {
        display: inline-block;
        height: ${ELEMENT_HEIGHT}px;
        color: #ffffff;
      }

      svg {
        font-size: 0;
      }

      .${ELEMENT_TAG}__filled-path,
      .${ELEMENT_TAG}--filled .${ELEMENT_TAG}__regular-path {
        display: none;
      }

      .${ELEMENT_TAG}--filled .${ELEMENT_TAG}__filled-path {
        display: inline;
      }

      svg path {
        /* currentColor allows for a forced-colors to override the svg colors */
        fill: currentColor;
      }

    </style>
    <svg width="16" height="${ELEMENT_HEIGHT}" viewBox="0 0 16 ${ELEMENT_HEIGHT}" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="${ELEMENT_TAG}__regular-path" fill-rule="evenodd" clip-rule="evenodd" d="M6.54472 2.60954C5.45403 1.48463 3.70006 1.48464 2.60939 2.60958C1.50057 3.75323 1.50057 5.62073 2.60939 6.76438L7.99999 12.3243L13.3871 6.76795L13.4392 6.713C14.4985 5.56641 14.4826 3.73943 13.3938 2.61293L13.3408 2.55942C12.248 1.4854 10.53 1.50107 9.45523 2.60958L7.99989 4.11061L6.54472 2.60954ZM1.32887 1.38465C3.11884 -0.461548 6.0353 -0.461548 7.82527 1.38465L7.99998 1.56487L8.17472 1.38465C9.93952 -0.435601 12.8002 -0.461712 14.5965 1.30944L14.6036 1.31645L14.6711 1.38461C16.4188 3.18714 16.4422 6.0802 14.744 7.91243L14.7372 7.91974L14.6711 7.98935L7.99999 14.87L1.32887 7.98931C-0.442958 6.16182 -0.442958 3.21214 1.32887 1.38465Z" />
      <path class="${ELEMENT_TAG}__filled-path" fill-rule="evenodd" clip-rule="evenodd" d="M1.30822 1.36997C3.06735 -0.456657 5.93121 -0.456657 7.69034 1.36997L7.99997 1.69153L8.30965 1.36997C10.0441 -0.43103 12.8532 -0.456731 14.6184 1.29546L14.6242 1.30123L14.6918 1.36994C16.4122 3.15636 16.4354 6.02587 14.7635 7.84188L14.758 7.8479L14.6918 7.91809L7.99999 14.8666L1.30822 7.91806C-0.436073 6.10684 -0.436073 3.1812 1.30822 1.36997Z" />
    </svg>
  `;
}

if (!customElements.get(ELEMENT_TAG)) {
  customElements.define(ELEMENT_TAG, ShopHeartIcon);
}

/**
 * @returns {ShopHeartIcon} a new HeartIcon instance
 */
export function createShopHeartIcon(): ShopHeartIcon {
  const element = document.createElement(ELEMENT_TAG);

  return element as ShopHeartIcon;
}
