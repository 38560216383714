import WebComponent from '../WebComponent';
import {colors} from '../colors';

const ELEMENT_TAG = 'shop-discount-icon';
const ELEMENT_HEIGHT = '24';

const ATTRIBUTE_VARIANT = 'variant';
export enum Variant {
  Regular = 'regular',
  Subdued = 'subdued',
  Branded = 'branded',
}

export class ShopDiscountIcon extends WebComponent {
  #rootElement: ShadowRoot;
  #svgElement: SVGSVGElement | null = null;

  static get observedAttributes(): string[] {
    return [ATTRIBUTE_VARIANT];
  }

  constructor() {
    super();

    const template = document.createElement('template');
    template.innerHTML = getTemplateContents();

    this.#rootElement = this.attachShadow({mode: 'open'});
    this.#rootElement.appendChild(template.content.cloneNode(true));

    this.#svgElement = this.#rootElement.querySelector('svg');
  }

  connectedCallback(): void {
    const variant = this.getAttribute(ATTRIBUTE_VARIANT);
    this._initVariant(variant);
  }

  disconnectedCallback(): void {}

  attributeChangedCallback(
    name: string,
    _oldValue: string,
    newValue: string | null,
  ): void {
    switch (name) {
      case ATTRIBUTE_VARIANT:
        this._initVariant(newValue);
        break;
    }
  }

  private _initVariant(variant: string | null) {
    const variantOptions: string[] = Object.values(Variant);
    const classSuffix =
      variant && variantOptions.includes(variant) ? variant : Variant.Regular;
    this.#svgElement?.setAttribute('class', `${ELEMENT_TAG}--${classSuffix}`);
  }
}

/**
 * @returns {string} HTML content for the icon
 */
function getTemplateContents(): string {
  return `
    <style>
      :host {
        display: inline-block;
        height: ${ELEMENT_HEIGHT}px;
      }

      svg {
        font-size: 0;
      }

      svg path {
        stroke-width: 2;
      }

      svg path, svg circle {
        transition: fill 200ms ease-in-out, stroke 200ms ease-in-out;
      }

      .${ELEMENT_TAG}--${Variant.Regular} path {
        fill: ${colors.white};
        stroke: ${colors.foregroundSecondary};
      }

      .${ELEMENT_TAG}--${Variant.Regular} circle {
        fill: ${colors.foregroundSecondary};
        stroke: ${colors.foregroundSecondary};
      }

      .${ELEMENT_TAG}--${Variant.Subdued} path {
        fill: ${colors.backgroundSubdued};
        stroke: ${colors.foregroundSecondary};
      }

      .${ELEMENT_TAG}--${Variant.Subdued} circle {
        fill: ${colors.foregroundSecondary};
        stroke: ${colors.foregroundSecondary};
      }

      .${ELEMENT_TAG}--${Variant.Branded} path {
        fill: ${colors.brand};
        stroke: ${colors.brand};
      }

      .${ELEMENT_TAG}--${Variant.Branded} circle {
        fill: ${colors.white};
        stroke: ${colors.white};
      }
    </style>
    <svg
      width="25"
      height="${ELEMENT_HEIGHT}"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.83337 11.1238V3.82557L4.64039 1.01855H11.9386L23.1667 12.2466L13.0614 22.3519L1.83337 11.1238Z" />
      <circle cx="7.44727" cy="6.63245" r="1.18421" />
    </svg>
  `;
}

if (!customElements.get(ELEMENT_TAG)) {
  customElements.define(ELEMENT_TAG, ShopDiscountIcon);
}

/**
 * @returns {ShopDiscountIcon} a new DiscountIcon instance
 */
export function createShopDiscountIcon(): ShopDiscountIcon {
  const element = document.createElement(ELEMENT_TAG);

  return element as ShopDiscountIcon;
}
