/**
 * Returns the value of the cookie with the given key.
 * @param {string} key The name of the cookie.
 * @returns {string | null} The value of the cookie or null if it doesn't exist.
 */
export function getCookie(key: string): string | null {
  try {
    const value = new RegExp(`(${key})=([^;]+)`).exec(document.cookie);
    return value ? value[2] : null;
  } catch (_ignore) {
    return null;
  }
}
