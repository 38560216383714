export interface Variant {
  id: number;
  quantity: number;
}

export enum PaymentOption {
  ShopPay = 'shop_pay',
  ShopPayInstallments = 'shop_pay_installments',
}

interface CartItem {
  /* eslint-disable @typescript-eslint/naming-convention */
  selling_plan_allocation?: {
    selling_plan?: {
      fixed_selling_plan: boolean;
    };
  };
  /* eslint-enable @typescript-eslint/naming-convention */
}

export interface Cart {
  currency: string;
  token: string;
  items: CartItem[];
  [key: string]: any;
}
