import {getAnalyticsTraceId} from './utils';

export class PayTimeoutError extends Error {
  name = 'PayTimeoutError';
  code = 'pay_timeout_error';

  constructor(
    message: string,
    public analyticsTraceId: string = getAnalyticsTraceId(),
  ) {
    super(message);
  }
}

export class MonorailProducerError extends Error {
  name = 'MonorailProducerError';
  code = 'monorail_producer_error';

  constructor(
    message: string,
    public analyticsTraceId: string = getAnalyticsTraceId(),
  ) {
    super(message);
  }
}

export class AbortSignalReceivedError extends Error {
  name = 'AbortSignalReceivedError';
  code = 'abort_signal_received_error';

  constructor(
    message: string,
    public analyticsTraceId: string = getAnalyticsTraceId(),
  ) {
    super(message);
  }
}

export class CustomElementAlreadyDefinedError extends Error {
  name = 'CustomElementAlreadyDefinedError';
  code = 'custom_element_already_defined_error';

  constructor(
    message: string,
    public analyticsTraceId: string = getAnalyticsTraceId(),
  ) {
    super(message);
  }
}
