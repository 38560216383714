export class LargeSpinner extends HTMLElement {
  #rootElement: ShadowRoot;

  constructor() {
    super();

    this.#rootElement = this.attachShadow({mode: 'open'});
  }

  connectedCallback() {
    const template = document.createElement('template');
    const size = this.getAttribute('size') || '';
    template.innerHTML = getTemplateContents(size);

    this.#rootElement.appendChild(template.content.cloneNode(true));
  }
}

/**
 * @param {string} size the size of the icon
 * @returns {string} HTML content for the logo.
 */
function getTemplateContents(size: string) {
  return `
    <style>

      @keyframes infiniteSpin {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }


      .Wrapper {
        fill: #5433EB;
        animation: infiniteSpin 1.3s linear infinite;
      }
    </style>
    <div>
    <svg fill="none"
    width=${size}
    height=${size} viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" class="Wrapper">
        <path class="LargeSpinner" d="M15.542 1.487A21.507 21.507 0 00.5 22c0 11.874 9.626 21.5 21.5 21.5 9.847 0 18.364-6.675 20.809-16.072a1.5 1.5 0 00-2.904-.756C37.803 34.755 30.473 40.5 22 40.5 11.783 40.5 3.5 32.217 3.5 22c0-8.137 5.3-15.247 12.942-17.65a1.5 1.5 0 10-.9-2.863z" />
    </svg>
    </div>
  `;
}

if (!customElements.get('large-spinner')) {
  customElements.define('large-spinner', LargeSpinner);
}

/**
 * helper function which creates an animated Shop logo and returns it to the parent
 * @returns {LargeSpinner} a large spinner
 */
export function createLargeSpinner(): LargeSpinner {
  const largeSpinner = document.createElement('large-spinner');
  largeSpinner.setAttribute('role', 'img');
  largeSpinner.setAttribute('size', '64');

  return largeSpinner as LargeSpinner;
}
