/**
 * Validates the presence of the provided value within the provided enum.
 * @param {unknown} value The value to validate against the enum.
 * @param {object} enumArg The enum to use for validation.
 * @returns {boolean} Whether the value provided is in the provided enum.
 */
function isEnumMember<T>(
  value: unknown,
  enumArg: Record<string | number | symbol, T>,
): value is T {
  return (Object.values(enumArg) as unknown[]).includes(value);
}

/**
 * Takes a value (attribute) and checks if the attribute is present within the provided enum.
 * This will safely overload and default back to a specific value if the attribute provided
 * to the web component is not present in the enum. This is an elegant way to handle values
 * provided to the web component and ensure that our components continue to operate as expected.
 * @param {unknown} value The value to validate against the enum.
 * @param {object} enumArg The enum to use for validation.
 * @param {string} fallback The value to fallback to in the event the provided value is not present in the enum.
 * @returns {string} A validated value that exists within the provided enum.
 */
export function getValidAttribute<T>(
  value: unknown,
  enumArg: Record<string | number | symbol, T>,
  fallback: T,
): T {
  if (isEnumMember(value, enumArg)) {
    return value;
  }
  return fallback;
}
