import {defineCustomElement} from '../../common/init';
import {ShopPayLogo} from '../../common/shop-pay-logo';

import {SHOP_PAY_BUTTON_BASE_HTML} from './constants';

export default class ShopPayButtonBase extends HTMLElement {
  static get observedAttributes() {
    return ['disabled', 'hide-logo', 'href'];
  }

  constructor() {
    super();

    if (!customElements.get('shop-pay-logo')) {
      customElements.define('shop-pay-logo', ShopPayLogo);
    }

    this.attachShadow({mode: 'open'});
  }

  connectedCallback() {
    this._updateButton();
  }

  attributeChangedCallback() {
    this._updateButton();
  }

  private _updateButton() {
    const baseElement = this.shadowRoot;
    if (!baseElement) return;

    const showLogo = !this.hasAttribute('hide-logo');
    baseElement.innerHTML = SHOP_PAY_BUTTON_BASE_HTML(showLogo);

    const linkElement = baseElement.querySelector('#shop-pay-button-link');
    const disabled = this.hasAttribute('disabled');
    const href = this.getAttribute('href');

    if (!linkElement) return;

    if (disabled) {
      linkElement.classList.toggle('disabled', true);
      linkElement.setAttribute('href', '#');
      return;
    }

    linkElement.setAttribute('href', href || '#');
  }
}

/**
 * Define the shop-pay-button-base custom element.
 */
export function defineElement() {
  defineCustomElement('shop-pay-button-base', ShopPayButtonBase);
}
