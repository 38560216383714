export const BUGSNAG_APP_ID = 'shop-js';

// Set by rollup replace plugin
export const APP_VERSION = '__buildVersion';

export const SHOPIFY_S_COOKIE = '_shopify_s';
export const SHOPIFY_Y_COOKIE = '_shopify_y';

export const SPIN_DOMAIN_SUFFIX = /spin\.(dev|shopify\.io)$/;
export const SPIN_URL_SUFFIX = /\.(?:(?:\w|-)+\.){3}spin\.(dev|shopify\.io)$/;

export const POPUP_CLOSE_CHECK_INTERVAL = 1000;

export const SIGN_IN_FORM_DATA_ATTRIBUTE = 'data-login-with-shop-sign-in';
export const SIGN_UP_FORM_DATA_ATTRIBUTE = 'data-login-with-shop-sign-up';
const SIGN_IN_FORM_SELECTOR = `form[${SIGN_IN_FORM_DATA_ATTRIBUTE}]`;
const SIGN_UP_FORM_SELECTOR = `form[${SIGN_UP_FORM_DATA_ATTRIBUTE}]`;

// See https://shopify.dev/docs/themes/customer-engagement/additional-customer-information for input field names
export const CUSTOMER_EMAIL_INPUT_SELECTOR = 'input[name="customer[email]"';
export const CUSTOMER_FIRST_NAME_INPUT_SELECTOR =
  'input[name="customer[first_name]"';
export const CUSTOMER_LAST_NAME_INPUT_SELECTOR =
  'input[name="customer[last_name]"';

export const SIGN_IN_FORM_EMAIL_INPUT_SELECTOR = `${SIGN_IN_FORM_SELECTOR} input[type="email"],${SIGN_IN_FORM_SELECTOR} ${CUSTOMER_EMAIL_INPUT_SELECTOR}`;
export const SIGN_UP_FORM_EMAIL_INPUT_SELECTOR = `${SIGN_UP_FORM_SELECTOR} input[type="email"],${SIGN_UP_FORM_SELECTOR} ${CUSTOMER_EMAIL_INPUT_SELECTOR}`;

export const MAX_Z_INDEX = '2147483647';
