const opentelErrorGrouping = {
  errorParsingCreatedAtMs: 'Error parsing: X-Monorail-Edge-Event-Created-At-Ms',
  emptyeEventCreatedAtMs: 'event_created_at_ms metadata field cannot be empty',
  noPermissionToGetURL: 'Your client does not have permission to get URL',
  noResponseFromEdge: 'No response from edge',
  incorrectContentType:
    'Incorrect Content-Type. Expected: application/json or text/plain',
  blockedRequest: 'Blocked Request',
  failedToReadRequestBody: 'Failed to read request body',
  methodNotAllowed: 'Method Not Allowed',
  schemaValidationError: 'Schema validation error',
};

/**
 * Group the Monorail errors into the most common categories
 * @param {Error} caughtError The error to check
 * @returns {string} the group of the error
 */
export function groupOpentelError(caughtError: Error) {
  const groupEntry = Object.entries(opentelErrorGrouping).find(([_, value]) =>
    caughtError.message.includes(value),
  );
  return groupEntry?.[0] || 'otherErrors';
}
