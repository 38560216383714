import {Monorail} from '@shopify/monorail';
import {MonorailRequestError} from '@shopify/monorail/lib/producers/producer-errors';
import {CompositeMonorailEvent} from '@shopify/monorail/lib/events/events';
import {
  recordOpentel,
  TelemetryMetricId,
} from '__deprecated__/dynamicImports/opentelemetry';

import Bugsnag from '../bugsnag';
import {InstallmentsBannerType} from '../../types';
import {getStorefrontAnalytics, MonorailProducerError} from '../utils';
import {APP_VERSION} from '../constants';

import {getTrekkieAttributes} from './trekkie';
import {
  LoginWithShopSdkPageImpressionEventProps,
  MonorailSchema,
  LoginWithShopSdkErrorEventProps,
  PageImpressionTracked,
  LoginWithShopSdkUserActionEventProps,
  TrackShopPayModalStateChangeParams,
  LoginWithShopFeatureInitializeEventProps,
  ShopifyPayModalState,
} from './types';
import {groupOpentelError} from './utils';

declare global {
  interface Window {
    Shopify: any;
    ShopifyAnalytics?: any;
    analytics?: any;
    trekkie?: any;
  }
}

export const monorailProducer =
  // eslint-disable-next-line no-process-env
  process.env.NODE_ENV === 'production'
    ? Monorail.createHttpProducer({production: true})
    : Monorail.createLogProducer({debugMode: true});

export default class MonorailTracker {
  protected readonly _elementName: string;
  protected readonly _analyticsTraceId: string | undefined;
  protected readonly _shopId?: number;
  protected readonly _shopPermanentDomain = '';
  protected _shopModalPreviousState: ShopifyPayModalState | undefined;

  protected readonly _checkoutVersion?: string;
  protected readonly _checkoutToken: string | undefined;
  protected _flowVersion: string;
  private readonly _flow: string;

  private _impressionTracked = false;
  private _shopLoginFirstTimeRenderTracked: Record<string, boolean> = {};
  private _pageImpressionTracked: PageImpressionTracked = {
    AUTHORIZE_MODAL: false,
    AUTHORIZE_MODAL_IN_VIEWPORT: false,
    CLASSIC_CUSTOMER_ACCOUNTS_ACCOUNT_PAGE: false,
    CLASSIC_CUSTOMER_ACCOUNTS_CREATE_ACCOUNT_PAGE: false,
    CLASSIC_CUSTOMER_ACCOUNTS_LOGIN_PAGE: false,
    COMPONENT_LOADED_FOLLOWING: false,
    COMPONENT_LOADED_NOT_FOLLOWING: false,
    CONTINUE_WITH_SHOP_PAGE: false,
    DISCOUNT_SAVE_CONFIRMATION_PAGE: false,
    DISCOUNT_SHOWN: false,
    FOLLOWING_GET_SHOP_APP_CTA: false,
    FOLLOW_BUTTON_SHOWN_IN_VIEWPORT: false,
    PARTNER_EMAIL_INPUT_SHOWN: false,
    SDK_HAS_LOADED_INITIAL_PAGE: false,
    SIGN_IN_WITH_SHOP_BUTTON: false,
    TEXT_MARKETING_SIGN_UP: false,
    TEXT_MARKETING_CONFIRMED_PAGE: false,
    TEXT_MARKETING_DECLINED_PAGE: false,
  };

  private _initTimestamp: number;

  /**
   * @param {object} params The parameters object.
   * @param {string} params.elementName The name of the element (e.g. `shop-pay-button`, `shop-login-button`, `shopify-payment-terms`).
   * @param {string} params.analyticsTraceId A UUID that can correlate all analytics events fired for the same user flow. I.e. Could be
   * @param {string} params.flow The SDK flow, eg. ('discount' or 'follow').
   * used to correlate events between Shop JS and Pay for the Shop Login flow.
   * @param {string} params.flowVersion The version of the Sign in with Shop flow (eg. "sign_in" or "sign_up")
   * @param {number} [params.shopId] The numeric id of the shop.
   * @param {number} [params.shopPermanentDomain] The 'myshopify' domain of the shop.
   * @param {string} [params.checkoutVersion] A checkout version such as "classic" or "shop_pay_external"
   * @param {string} [params.checkoutToken] A checkout token if present.
   */
  constructor({
    elementName,
    analyticsTraceId,
    flow = '',
    flowVersion = 'unspecified',
    shopId,
    shopPermanentDomain,
    checkoutVersion,
    checkoutToken,
  }: {
    elementName: string;
    analyticsTraceId?: string;
    flow?: string;
    flowVersion?: string;
    shopId?: number;
    shopPermanentDomain?: string;
    checkoutVersion?: string;
    checkoutToken?: string;
  }) {
    this._elementName = elementName;
    this._flow = flow;
    this._analyticsTraceId = analyticsTraceId;
    this._initTimestamp = new Date().getTime();
    this._flowVersion = flowVersion;
    this._checkoutVersion = checkoutVersion;
    this._checkoutToken = checkoutToken;
    this._shopId = shopId;
    this._shopPermanentDomain =
      shopPermanentDomain || window.Shopify?.shop || '';
    this._shopModalPreviousState = undefined;
  }

  get analyticsTraceId() {
    return this._analyticsTraceId;
  }

  /**
   * Fired when a component from shop-js is mounted on the page.
   * @param {InstallmentsBannerType} elementType The type element that emitted the event. Currently supported only on `shopify-payment-terms`.
   */
  async trackElementImpression(
    elementType?: InstallmentsBannerType,
  ): Promise<void> {
    if (this._impressionTracked) {
      return;
    }
    this._impressionTracked = true;

    const trekkieAttributes = await getTrekkieAttributes(
      'uniqToken',
      'visitToken',
      'microSessionId',
      'microSessionCount',
      'shopId',
      'themeId',
      'themeCityHash',
      'contentLanguage',
      'referer',
    );

    const payload = {
      ...trekkieAttributes,
      elementType,
      elementName: this._elementName,
      shopJsVersion: APP_VERSION,
    };

    produceMonorailEvent(
      {
        schemaId: MonorailSchema.UiImpression,
        payload,
      },
      trekkieAttributes,
      () => {
        this._impressionTracked = false;
      },
    );
  }

  /**
   * Fired when the page need to be tracked.
   * @param {object} params The parameters object.
   * @param {object} params.shopAccountUuid The shop account uuid.
   * @param {string} params.apiKey The API key of the app.
   * @param {object} params.page The page's impression to track.
   */
  async trackPageImpression({
    shopAccountUuid,
    apiKey,
    page,
    allowDuplicates = false,
  }: LoginWithShopSdkPageImpressionEventProps): Promise<void> {
    if (!allowDuplicates && this._pageImpressionTracked[page]) {
      return;
    }
    this._pageImpressionTracked[page] = true;

    const trekkieAttributes = await getTrekkieAttributes(
      'uniqToken',
      'visitToken',
      'isPersistentCookie',
      'path',
      'customerId',
    );

    const storefrontAnalytics = getStorefrontAnalytics();
    const storefrontPageType = storefrontAnalytics?.pageType ?? '';

    const payload = {
      ...trekkieAttributes,
      analyticsTraceId: this._analyticsTraceId!,
      flow: this._flow,
      flowVersion: this._flowVersion,
      pageName: page,
      sdkVersion: APP_VERSION,
      shopPermanentDomain: this._shopPermanentDomain,
      storefrontPageType,
      ...(apiKey && {apiKey}),
      ...(shopAccountUuid && {shopAccountUuid}),
      ...(this._checkoutToken && {checkoutToken: this._checkoutToken}),
    };

    produceMonorailEvent(
      {
        schemaId: MonorailSchema.LoginWithShopSdkPageImpression,
        payload,
      },
      trekkieAttributes,
      () => {
        this._pageImpressionTracked[page] = false;
      },
    );
  }

  /**
   * Fired when the Login with Shop UI has finished its initial render (including the UI rendered by Pay). This is Unique
   * to the Login with Shop flows because they execute a network request to Pay before rendering the UI.
   * @param {string} eventName The name of the event. Defaults to the flow version.
   * @param {number} startTime The start time to use to calculate `duration`. If not provided it will default to the
   * moment the class was instantiated.
   */
  async trackShopLoginFirstTimeRender(
    eventName: string = this._flowVersion,
    startTime: number = this._initTimestamp,
  ): Promise<void> {
    if (this._shopLoginFirstTimeRenderTracked[eventName]) {
      return;
    }
    this._shopLoginFirstTimeRenderTracked[eventName] = true;

    const timestamp = new Date().getTime();
    const duration = timestamp - startTime;

    const trekkieAttributes = await getTrekkieAttributes('shopId');

    const payload = {
      analyticsTraceId: this._analyticsTraceId,
      duration,
      ...trekkieAttributes,
      shopLoginVersion: eventName,
      url: window.location.href,
      userAgent: navigator.userAgent,
    };

    produceMonorailEvent(
      {
        schemaId: MonorailSchema.ShopLoginFirstTimeRender,
        payload,
      },
      trekkieAttributes,
      () => {
        this._shopLoginFirstTimeRenderTracked[eventName] = false;
      },
    );
  }

  trackShopPayLoginWithShopSdkUserAction({
    apiKey,
    userAction,
  }: LoginWithShopSdkUserActionEventProps) {
    const payload = {
      ...(apiKey && {apiKey}),
      flow: this._flow,
      flowVersion: this._flowVersion,
      sdkVersion: APP_VERSION,
      analyticsTraceId: this._analyticsTraceId!,
      ...(this._checkoutVersion && {checkoutVersion: this._checkoutVersion}),
      ...(this._shopId && {shopId: this._shopId}),
      shopPermanentDomain: this._shopPermanentDomain,
      userAction,
    };

    produceMonorailEvent({
      schemaId: MonorailSchema.LoginWithShopSdkUserAction,
      payload,
    });
  }

  /**
   * Fired when the API emits error events
   * @param {object} params The parameters object.
   * @param {string} params.apiKey The API key of the app.
   * @param {string} params.errorCode The error code emitted by the API.
   * @param {string} params.errorMessage The error message emitted by the API.
   */
  trackShopPayLoginWithSdkErrorEvents({
    apiKey,
    errorCode,
    errorMessage,
  }: LoginWithShopSdkErrorEventProps) {
    const payload = {
      apiKey,
      flow: this._flow,
      flowVersion: this._flowVersion,
      sdkVersion: APP_VERSION,
      analyticsTraceId: this._analyticsTraceId,
      shopPermanentDomain: this._shopPermanentDomain,
      errorCode,
      errorMessage,
    };

    produceMonorailEvent({
      schemaId: MonorailSchema.LoginWithShopSdkErrorEvents,
      payload,
    });
  }

  /**
   * Tracks the state change of the Shop Pay Verification Modal at guest checkout.
   * @param {object} props - Monorail event's payload
   * @param {string} props.currentState - The current state of the modal.
   * @returns {void}
   */
  trackShopPayModalStateChange({
    currentState,
    reason,
    dismissMethod,
  }: TrackShopPayModalStateChangeParams): void {
    if (this._checkoutToken) {
      const payload = {
        checkoutToken: this._checkoutToken,
        checkoutVersion: this._checkoutVersion,
        shopId: this._shopId,
        shopifyDomain: this._shopPermanentDomain,
        previousState: '',
        currentState,
        analyticsTraceId: this._analyticsTraceId,
        clientTimestampMs: new Date().getTime(),
        zoom: `${window.visualViewport?.scale}`,
      };

      produceMonorailEvent({
        schemaId: MonorailSchema.ShopifyPayModalStateChange,
        payload,
      });
    }

    if (this._flow && this._flowVersion) {
      produceMonorailEvent({
        schemaId: MonorailSchema.LoginWithShopModalStateChange,
        payload: {
          currentState,
          previousState: this._shopModalPreviousState,
          reason,
          dismissMethod,
          flow: this._flow,
          flowVersion: this._flowVersion,
          analyticsTraceId: this._analyticsTraceId,
          zoom: `${window.visualViewport?.scale}`,
        },
      });
      this._shopModalPreviousState = currentState;
    }
  }

  /**
   * Fired when the page need to be tracked.
   * @param {object} params The parameters object.
   * @param {string} params.apiKey The API key of the app.
   * @param {object} params.source The source which rendered the Sign in with Shop component.
   */
  async trackFeatureInitialization({
    apiKey,
    source,
  }: LoginWithShopFeatureInitializeEventProps): Promise<void> {
    const trekkieAttributes = await getTrekkieAttributes(
      'uniqToken',
      'visitToken',
      'isPersistentCookie',
      'path',
      'customerId',
    );

    const storefrontAnalytics = getStorefrontAnalytics();
    const storefrontPageType = storefrontAnalytics?.pageType ?? '';

    const payload = {
      ...trekkieAttributes,
      analyticsTraceId: this._analyticsTraceId!,
      flow: this._flow,
      flowVersion: this._flowVersion,
      sdkVersion: APP_VERSION,
      shopPermanentDomain: this._shopPermanentDomain,
      source,
      storefrontPageType,
      ...(apiKey && {apiKey}),
      ...(this._checkoutToken && {checkoutToken: this._checkoutToken}),
    };

    produceMonorailEvent(
      {
        schemaId: MonorailSchema.LoginWithShopFeatureInitialize,
        payload,
      },
      trekkieAttributes,
    );
  }
}

/**
 *
 * @param {CompositeMonorailEvent} monorailEvent The monorail event to be produced
 * @param {object} trekkieAttributes The attributes retrieved from trekkie. If provided, they will be merged with the monorail event payload.
 * If empty but defined they will block the monorail event from being produced.
 * @param {Function} onError A function to be called in the case that an error is thrown while producing a monorail event
 */
export function produceMonorailEvent(
  monorailEvent: CompositeMonorailEvent,
  trekkieAttributes?: object,
  onError?: (obj?: object) => void,
): void {
  if (trekkieAttributes && !Object.keys(trekkieAttributes).length) {
    // if trekkie attributes are provided but the object is empty then we don't want to send the event
    // since we knew it will not have a valid schema
    onError?.({message: 'trekkie attributes are empty'});
    return;
  }

  monorailEvent.payload = Object.assign(
    monorailEvent.payload,
    trekkieAttributes,
  );
  monorailProducer.produce(monorailEvent).catch((error) => {
    onError?.(error);

    if (isUsefulError(error)) {
      const caughtError =
        error instanceof Error
          ? error
          : new MonorailProducerError(String(error));

      const opentelError = groupOpentelError(caughtError);
      Bugsnag.notify(caughtError);
      recordOpentel(TelemetryMetricId.MonorailProducerError, 1, {
        error: opentelError,
      });
    }
  });
}

/**
 * Silence network request errors, which are likely caused by browser ad blockers.
 * See: https://github.com/Shopify/shop-identity/issues/1664
 * https://github.com/Shopify/shop-identity/issues/2008
 * @param {any} error The error to check
 * @returns {boolean} whether the error is useful or not
 */
function isUsefulError(error: any): boolean {
  return (
    !(error instanceof MonorailRequestError) &&
    !error?.message?.includes('Invalid agent:')
  );
}
