import {colors} from '../colors';

export class ShopCloseIcon extends HTMLElement {
  constructor() {
    super();

    const template = document.createElement('template');
    const size = this.getAttribute('size') || '';

    const compact = this.getAttribute('compact');
    const isCompact = compact !== null && compact !== undefined;

    if (isCompact) {
      template.innerHTML = getCompactTemplateContents(size);
    } else {
      template.innerHTML = getTemplateContents(size);
    }

    this.attachShadow({mode: 'open'}).appendChild(
      template.content.cloneNode(true),
    );
  }
}

/**
 * @param {string} size the size of the icon
 * @returns {string} HTML content for the icon.
 */
function getTemplateContents(size: string) {
  return `
    <style>
      :host {
        display: flex;
      }

      svg path {
        fill: var(--icon-color, ${colors.close});
      }
    </style>
    <svg
        aria-hidden="true"
        width=${size}
        height=${size}
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.4875 1.5877L14.9125 0.0126953L8.5 6.4252L2.0875 0.0126953L0.512497 1.5877L6.925 8.0002L0.512497 14.4127L2.0875 15.9877L8.5 9.5752L14.9125 15.9877L16.4875 14.4127L10.075 8.0002"
        />
    </svg>
  `;
}

/**
 * @param {string} size the size of the icon
 * @returns {string} HTML content for the icon.
 */
function getCompactTemplateContents(size: string) {
  return `
    <style>
      :host {
        display: flex;
      }

      svg path {
        fill: var(--icon-color, ${colors.backgroundFaint});
      }
    </style>
    <svg
        aria-hidden="true"
        width=${size}
        height=${size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM7.70711 6.29289C7.31658 5.90237 6.68342 5.90237 6.29289 6.29289C5.90237 6.68342 5.90237 7.31658 6.29289 7.70711L8.58579 10L6.29289 12.2929C5.90237 12.6834 5.90237 13.3166 6.29289 13.7071C6.68342 14.0976 7.31658 14.0976 7.70711 13.7071L10 11.4142L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L11.4142 10L13.7071 7.70711C14.0976 7.31658 14.0976 6.68342 13.7071 6.29289C13.3166 5.90237 12.6834 5.90237 12.2929 6.29289L10 8.58579L7.70711 6.29289Z" fill="currentColor"/>
    </svg>
  `;
}
