export const COOKIE_EXCHANGE_PATH_CORE = '/services/login_with_shop/finalize';

export const buildCookieExchangeUrl = (origin: string): string => {
  return `${origin}${COOKIE_EXCHANGE_PATH_CORE}`;
};

/**
 * function which exchanges the temporary login cookie for the proper pay one
 * @param {string} storefrontOrigin - the origin string of the storefront
 * @param {Function} errorCallback - a function which covers the error path
 * for when the API is unresponsive; note that all other errors are handled server-side
 * @returns {Promise} Api request promise
 */
export function exchangeLoginCookie(
  storefrontOrigin: string = window.location.origin,
  errorCallback: (error: any) => void,
): Promise<void | Response> {
  const cookieExchangeUrl = buildCookieExchangeUrl(storefrontOrigin);
  return fetch(cookieExchangeUrl).catch(errorCallback);
}
